@import "./variables.scss";

// change the theme
$theme-colors: (
  "primary": $primary-color,
);

$font-family-base: "Lato", avenir, -apple-system, sans-serif;
$headings-font-family: "Bebas Neue", Helvetica, sans-serif;
$btn-font-weight: 700;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lead {
  font-family: $font-family-base;
  font-weight: normal;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.sticky-top {
  z-index: 100;
}

main {
  flex: 1;
}

img {
  max-width: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}
